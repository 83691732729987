module.exports = {
  "short_name": "Emmanuel",
  "name": "Emmanuel's WAAPI experiment",
  "icons": [
    {
      "src": "apple-touch-icon.png",
      "sizes": "152x152",
      "type": "image/png"
    }
  ],
  "background_color": "#ffd600",
  "display": "standalone",
  "theme_color": "#ffd600"
}
;